import { TimeframeMenu } from '../Menus/TimeFrameMenu';
import { useOverviewContext } from '../../hooks/useOverviewContext';
import { Header } from 'semantic-ui-react';
import { useState } from 'react';

const firstTab = ['Region', 'Division']
export const withOverviewHeader = (WrappedComponent, name) => {
  return () => {
    const {
      timeframe,
      settimeframe,
      unit,
      setUnit,
      dataAsOf,
      rgnNbr,
      levelName,
      metricsByDistrict,
      SafetyIncOshaLtGlbiByRgnData,
      divNbr,
      level
    } = useOverviewContext();
    const [metric, setMetric] = useState('Sales');
    return (
      <>
        <TimeframeMenu timeframe={timeframe} settimeframe={settimeframe} unit={unit} setUnit={setUnit} showUnits />
        {firstTab.includes(name) && (
          <Header textAlign='center' style={{ textTransform: 'capitalize' }}>
            Overview - {name} {rgnNbr}, {levelName}
            <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>
          </Header>
        )}
        <WrappedComponent
          timeframe={timeframe}
          metricsByDistrict={metricsByDistrict}
          unit={unit}
          SafetyIncOshaLtGlbiByRgnData={SafetyIncOshaLtGlbiByRgnData}
          dataAsOf={dataAsOf}
          setMetric={setMetric}
          metric={metric}
          levelNumber={rgnNbr || divNbr}
          level={level}
        />
      </>
    );
  };
};
