import { Header, Loader, Tab } from 'semantic-ui-react';
import { useOverviewContext } from '../../../hooks/useOverviewContext';
import { withOverviewHeader } from '../../HOC/withHeader';
import { SalesBySubLevelList } from './SalesBySubLevelList';
import { OverviewTabs } from './OverviewTabs';

const RegionBody = withOverviewHeader(OverviewTabs, 'Region');
const DistrictBody = withOverviewHeader(SalesBySubLevelList, 'District-List');
const DivisionBody = withOverviewHeader(OverviewTabs, 'Division');
const RegionListBody = withOverviewHeader(SalesBySubLevelList, 'Region-List');

const tabsMenu = {
  region: [
    {
      menuItem: 'Region',
      render: () => <RegionBody />
    },
    {
      menuItem: 'District List',
      render: () => <DistrictBody />
    }
  ],
  division: [
    {
      menuItem: 'Division',
      render: () => <DivisionBody />
    },
    {
      menuItem: 'Region List',
      render: () => <RegionListBody />
    }
  ]
};

export const OverviewMenu = () => {
  const { isError, isOverviewTabsLoading, level } = useOverviewContext();
  if (isError) return <h1>Something went wrong</h1>;
  if (isOverviewTabsLoading) return <Loader active>Loading</Loader>;
  if (false) {
    //TODO: replace value with correct validation field
    return <Header textAlign='center'>Invalid or Unauthorized District</Header>;
  }

  return <Tab panes={tabsMenu[level]} menu={{ secondary: true, pointing: true }} style={{ marginBottom: '1em' }} />;
};
