import { useState } from 'react';
import { Header, Loader, Grid } from 'semantic-ui-react';
import { useQuery } from 'react-query';
import { useQueryParams } from '../../hooks/useQueryParams';
import { getData } from '../../service/DataService';
import { getCalloutStyle, getThousandDollarString } from '../Common/TableCellUtils';
import { DataTable } from '../Common/DataTable';
import { MenuItems } from '../Common/MenuItems';
import { WTDSalesByDepartment } from './DailyView/WTDSalesByDepartment';
import { DataView } from '../Common/DataView';

const WTD_QUERIES = {
  store: 'queryWtdSlsByStr',
  district: 'queryWtdSlsByDst',
  region: 'queryWtdSlsByRgn'
};

export const DailyView = ({ fpToggle, level, levelName }) => {
  const { dstNbr, rgnNbr, strNbr } = useQueryParams();
  const [wtdOrScorecard, setWtdOrScorecard] = useState('WTD Sales');
  const [metric, setMetric] = useState('Sales Plan');
  const [timeframe, setTimeframe] = useState('FISCAL DAY');
  const [fiscalYear, setFiscalYear] = useState('All');
  const [dateFormat, setDateFormat] = useState('PERIOD_END_DT');
  const locNbr = rgnNbr || dstNbr || strNbr;
  const wtdOverviewQuery = useQuery([`query/wtdSalesByDepartment/${level}`, { locNbr, fpToggle }], getData);
  const dailyQuery = useQuery([`query/wtdDailySalesByDepartment/${level}`, { locNbr, fpToggle }], getData);
  const salesHistoryQuery = useQuery([`query/salesHistory/${level}`, { locNbr, fpToggle }], getData);
  const wtdQuery = useQuery([WTD_QUERIES[level], { locNbr, fpToggle }], getData);

  if (wtdOverviewQuery.isLoading || wtdQuery.isLoading || dailyQuery.isLoading || salesHistoryQuery.isLoading) {
    return <Loader active>Loading...</Loader>;
  }
  if (!wtdOverviewQuery.data[0] || !wtdQuery.data[0] || !dailyQuery.data[0]) {
    return <Header textAlign='center'>Invalid Store Number</Header>;
  }

  const wtdData = wtdQuery.data[0];

  const slsHistData = salesHistoryQuery.data || [];
  const fiscalYears = slsHistData.reduce((acc, curr) => {
    return acc.includes(curr['FSCL_YR']) ? acc : [...acc, curr['FSCL_YR']];
  }, []);
  const fiscalYearsSorted = fiscalYears.sort();

  const slsHistDataFiltered = slsHistData.filter(row => {
    return row && row['AGG_TYPE'] === timeframe && (fiscalYear === 'All' || row['FSCL_YR'].toString() === fiscalYear);
  });

  const wtdDataObj = {
    data: [
      [
        {
          stringValue: wtdData['ABBR_DAY_NM']
        },
        {
          customStyle: getCalloutStyle(wtdData['WTD_SLS_AMT']),
          stringValue: getThousandDollarString(wtdData['WTD_SLS_AMT'], 1)
        },
        {
          customStyle: getCalloutStyle(wtdData['DOL_TO_WTD_PLN']),
          stringValue: getThousandDollarString(wtdData['DOL_TO_WTD_PLN'], 1)
        },
        {
          customStyle: getCalloutStyle(wtdData['PCT_TO_WTD_PLN']),
          decimals: 1,
          pctValue: wtdData['PCT_TO_WTD_PLN']
        },
        {
          customStyle: getCalloutStyle(wtdData['WTD_COMP_PCT']),
          decimals: 1,
          pctValue: wtdData['WTD_COMP_PCT']
        },
        {
          customStyle: getCalloutStyle(wtdData['WTD_PLANNED_COMP_PCT']),
          decimals: 1,
          pctValue: wtdData['WTD_PLANNED_COMP_PCT']
        }
      ]
    ],
    headers: [
      { name: 'As Of' },
      {
        element: (
          <span>
            WTD Sales <br />$ (k)
          </span>
        ),
        name: 'wtd-sales-k'
      },
      {
        element: (
          <span>
            $ vPlan <br />
            WTD (k)
          </span>
        ),
        name: 'vplan-k'
      },
      {
        element: (
          <span>
            % to <br />
            WTD Plan
          </span>
        ),
        name: 'to-wtd-plan'
      },
      {
        element: (
          <span>
            WTD <br />
            Comp %
          </span>
        ),
        name: 'wtd-comp'
      },
      {
        element: (
          <span>
            WTD Planned
            <br />
            Comp %
          </span>
        ),
        name: 'wtd-planned-comp'
      }
    ]
  };

  const dailySalesDataObj = {
    data: dailyQuery.data.map(row => [
      {
        orderBy: row['DAY_CODE'],
        stringValue: row['ABBR_DAY_NM']
      },
      {
        stringValue: getThousandDollarString(row['TY_SLS_AMT'], 1)
      },
      {
        customStyle: getCalloutStyle(row['DOL_TO_PLN']),
        stringValue: getThousandDollarString(row['DOL_TO_PLN'], 1)
      },
      {
        customStyle: getCalloutStyle(row['PCT_TO_PLN']),
        decimals: 1,
        pctValue: row['PCT_TO_PLN']
      },
      {
        customStyle: getCalloutStyle(row['COMP_PCT']),
        decimals: 1,
        pctValue: row['COMP_PCT']
      },
      {
        customStyle: getCalloutStyle(row['PLANNED_COMP_PCT']),
        decimals: 1,
        pctValue: row['PLANNED_COMP_PCT']
      }
    ]),
    headers: [
      { name: 'Day' },
      {
        element: (
          <span>
            Daily <br />
            Sales $ (k)
          </span>
        ),
        name: 'daily-sales-k'
      },
      { name: '$ vPlan (k)' },
      {
        element: (
          <span>
            % to <br />
            Daily Plan
          </span>
        ),
        name: 'to-daily-plan'
      },
      { name: 'Comp %' },
      {
        element: (
          <span>
            Planned
            <br />
            Comp %
          </span>
        ),
        name: 'planned-comp'
      }
    ],
    sortable: true,
    sortableDefault: 0,
    sortableColumns: [0]
  };

  const isDateFormatFiscalYear = dateFormat === 'PERIOD_DESC';
  const yearScoreCardDataObj = {
    data: slsHistDataFiltered.map(row =>
      [
        isDateFormatFiscalYear && {
          stringValue: row.FSCL_YR
        },
        {
          orderBy: isDateFormatFiscalYear ? row.PERIOD_END_DT : undefined,
          stringValue: row[dateFormat]
        },
        timeframe === 'FISCAL DAY' && { stringValue: row['DAY_DESC'] },
        (metric === 'Sales Plan' || metric === 'Sales Comp') && { dollarValue: row['TY_SLS_AMT'] },
        metric === 'Sales Plan' && { dollarValue: row['SLS_PLN_AMT'] },
        metric === 'Sales Plan' && { pctValue: row['TY_SLS_AMT'] / row['SLS_PLN_AMT'] - 1 },
        metric === 'Sales Comp' && { dollarValue: row['LY_SLS_AMT'] },
        metric === 'Sales Comp' && { pctValue: row['TY_SLS_AMT'] / row['LY_SLS_AMT'] - 1 },
        metric === 'Labor' && { stringValue: row['ACT_HRS'].toLocaleString('en-US', { maximumFractionDigits: 0 }) },
        metric === 'Labor' && { stringValue: row['FCST_HRS'].toLocaleString('en-US', { maximumFractionDigits: 0 }) },
        metric === 'Labor' && { pctValue: row['ACT_HRS'] / row['FCST_HRS'] - 1 },
        metric === 'Markdowns' && { pctValue: row['SMKDN_AMT'] / row['TY_SLS_AMT'] },
        metric === 'Markdowns' && { pctValue: row['SMD_PCT_PLN'] },
        metric === 'Markdowns' && {
          decimals: 0,
          numValue: 10000 * (row['SMKDN_AMT'] / row['TY_SLS_AMT'] - row['SMD_PCT_PLN']),
          postfix: ' bps'
        }
      ].filter(Boolean)
    ),
    headers: SCORE_CARD_HEADERS.filter(
      ({ dateFormat: dateFormatH, metric: metricH, timeframe: timeframeH }) =>
        (!timeframeH || timeframeH.includes(timeframe)) &&
        (!metricH || metricH.includes(metric)) &&
        (!dateFormatH || dateFormatH.includes(dateFormat))
    ),
    pagination: true,
    sortable: true,
    sortableDefault: isDateFormatFiscalYear ? 1 : 0,
    sortableColumns: [isDateFormatFiscalYear ? 1 : 0]
  };

  return (
    <>
      <Header textAlign='center' style={{ textTransform: 'capitalize' }}>
        Daily Sales - {level} {strNbr || rgnNbr || dstNbr}, {levelName}
      </Header>

      <MenuItems
        activeItem={wtdOrScorecard}
        items={['WTD Sales', 'Sales Scorecard']}
        setItem={setWtdOrScorecard}
        size='small'
      />

      {wtdOrScorecard === 'WTD Sales' && (
        <>
          <Header textAlign='center' style={{ textTransform: 'capitalize' }}>
            WTD Sales
          </Header>
          <DataTable data={wtdDataObj} />
          <br />
          <DataView key={'dailySalesView'} testId={'dailySalesView'}>
            <DataView.Title position={'center'}>Daily Sales</DataView.Title>
            <DataView.DataTable data={dailySalesDataObj} />
          </DataView>

          <WTDSalesByDepartment data={wtdOverviewQuery.data} level={level} locNbr={locNbr} />
        </>
      )}
      {wtdOrScorecard === 'Sales Scorecard' && (
        <>
          <Grid>
            <Grid.Column width={8} textAlign='left'>
              <Grid.Row>&nbsp;</Grid.Row>
              Metric:
              <div>
                <select
                  name='metric'
                  onChange={e => {
                    setMetric(e.target.value);
                  }}
                  value={metric}
                  style={{ padding: 10 }}>
                  <option value='Sales Plan' selected>
                    Sales Plan
                  </option>
                  <option value='Sales Comp'>Sales Comp</option>
                  <option value='Labor'>Labor</option>
                  <option value='Markdowns'>Markdowns</option>
                </select>
              </div>
              <Grid.Row>&nbsp;</Grid.Row>
              Timeframe:
              <div>
                <select
                  name='timeframe'
                  onChange={e => {
                    setTimeframe(e.target.value);
                  }}
                  value={timeframe}
                  style={{ padding: 10 }}>
                  <option value='FISCAL DAY' selected>
                    Day
                  </option>
                  <option value='FISCAL WEEK'>Week</option>
                  <option value='FISCAL MONTH'>Month</option>
                  <option value='FISCAL QUARTER'>Quarter</option>
                </select>
              </div>
              <Grid.Row>&nbsp;</Grid.Row>
            </Grid.Column>
            <Grid.Column width={8} textAlign='right'>
              <Grid.Row>&nbsp;</Grid.Row>
              Fiscal Year:
              <div>
                <select
                  name='fiscalYear'
                  onChange={e => {
                    setFiscalYear(e.target.value);
                  }}
                  value={fiscalYear}
                  style={{ padding: 10 }}>
                  <option value='All'>All</option>
                  {fiscalYearsSorted.map(row => {
                    return (
                      <option key={row} value={row}>
                        {row}
                      </option>
                    );
                  })}
                </select>
              </div>
              <Grid.Row>&nbsp;</Grid.Row>
              Date Format:
              <div>
                <select
                  name='dateFormat'
                  onChange={e => {
                    setDateFormat(e.target.value);
                  }}
                  value={dateFormat}
                  style={{ padding: 10 }}>
                  <option selected value='PERIOD_END_DT'>
                    Date
                  </option>
                  <option value='PERIOD_DESC'>Fiscal Period</option>
                </select>
              </div>
              <Grid.Row>&nbsp;</Grid.Row>
            </Grid.Column>
          </Grid>

          <DataView>
            <DataView.Title>Full Year Scorecard</DataView.Title>

            <DataView.DataTable data={yearScoreCardDataObj} />
          </DataView>
        </>
      )}
    </>
  );
};

const SCORE_CARD_HEADERS = [
  { name: 'Year', dateFormat: ['PERIOD_DESC'] },
  { name: 'Time Period' },
  { name: 'Day Name', timeframe: ['FISCAL DAY'] },
  { name: 'Sales $', metric: ['Sales Plan', 'Sales Comp'] },
  { name: 'Sales Plan $', metric: ['Sales Plan'] },
  { name: 'vP %', metric: ['Sales Plan'] },
  { name: 'LY Sales $', metric: ['Sales Comp'] },
  { name: 'vLY %', metric: ['Sales Comp'] },
  { name: 'Labor Hours', metric: ['Labor'] },
  { name: 'Fcst Hours', metric: ['Labor'] },
  { name: 'vF %', metric: ['Labor'] },
  { name: 'Mkdn %', metric: ['Markdowns'] },
  { name: 'Mkdn Plan %', metric: ['Markdowns'] },
  { name: 'vP bps', metric: ['Markdowns'] }
];
