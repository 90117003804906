

// VOC Goals
export const VOC_LTPA_GOAL = 0.75
export const VOC_LTSA_GOAL = 0.80
export const VOC_GET_GOAL = 0.80
export const VOC_SHELF_AVAILABILITY_GOAL = 0.90
export const VOC_CASHIER_FRIENDLINESS_GOAL = 0.80


// OSA Goals
export const OSA_SIDEKICK_PURGE_GOAL = 0.98
export const OSA_SIDEKICK_SKUBAY_GOAL = 0.98
export const OSA_OVERHEAD_IMAGING_GOAL = 0.85
export const OSA_OHM_NO_LOC_TAGS_GOAL = 0.03
export const OSA_URGENT_TASK_EXEC_GOAL = 1
export const OSA_OH_AUTO_REJECT_RATE_GOAL = 0.03
