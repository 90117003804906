import { useMemo } from 'react';
import useQueryApi from './useQueryApi';

export const useOsaSideKickExeScoresPlusOneQuery = ({ level, locNbr, fpToggle }) => {
  const { data = [], isError, isLoading } = useQueryApi(`query/sidekickExeScoresPlusOne/${level}`, { locNbr, fpToggle });
  const dataAsMap = useMemo(() => {
    if (isLoading) return {};

    return Object.fromEntries(data.map(row => [row.DST_NBR, row]));
  }, [data, isLoading]);

  return { data, dataAsMap, isDataEmpty: data.length === 0, isError, isLoading };
}
