export const queryParamByLevel = ({ level, levelNumber }) => {
  const urlParam = {
    store: { strNbr: levelNumber },
    district: { dstNbr: levelNumber }
  }
  return urlParam[level] || { locNbr: levelNumber }
}

export const columnName = {
  store: 'STR_NM',
  district: 'DST_NM',
  region: 'RGN_NM',
  division: 'DIV_NM'
}

export const METRIC_CONSTANTS = {
  store: {
    METRIC_LEVEL: 'STR',
    urlParam: 'strNbr',
    subMetric: 'STR',
    urlLinkLevel: 'store',
    urlSubLevelParam: 'strNbr',
    propToRender: 'STR_NBR',
    currentLevel: 'STR_NBR',
    FILTER_BY_METRIC: 'STR'
  },
  district: {
    METRIC_LEVEL: 'DST',
    urlParam: 'dstNbr',
    subMetric: 'STR',
    urlLinkLevel: 'store',
    urlSubLevelParam: 'strNbr',
    propToRender: 'STR_NBR',
    currentLevel: 'DST_NBR',
    FILTER_BY_METRIC: 'STR'
  },
  region: {
    METRIC_LEVEL: 'RGN',
    urlParam: 'rgnNbr',
    subMetric: 'DST',
    urlLinkLevel: 'district',
    urlSubLevelParam: 'dstNbr',
    propToRender: 'DST_NBR',
    currentLevel: 'RGN_NBR',
    FILTER_BY_METRIC: 'DST'
  },
  division: {
    METRIC_LEVEL: 'DIV',
    urlParam: 'divNbr',
    subMetric: 'RGN',
    urlLinkLevel: 'region',
    urlSubLevelParam: 'rgnNbr',
    propToRender: 'RGN_NBR',
    currentLevel: 'DIV_NBR',
    FILTER_BY_METRIC: 'RGN'
  }
}
