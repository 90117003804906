import { useFetchLevelName } from '../../hooks/useFetchLevelName';
import { useOsaSideKickSkuBayQuery } from '../../hooks/useOsaSideKickSkuBayQuery';
import { useOsaSideKickExeScoresPlusOneQuery } from '../../hooks/useOsaSideKickExeScoresPlusOneQuery';

import { OSA_SIDEKICK_SKUBAY_GOAL } from '../../constants/goals';
import {
  getCompletedTaskCount,
  getSkuBayCompletedTasks,
  getSkuBayTaskGoal,
  getTotalTaskCount,
  getSkuPackDown,
  increaseManagerData,
  increaseSidekickData,
  initDistrictSummaryData,
  isTaskOriginManager,
  isTaskOriginSidekick,
  isTaskTypeLogged
} from '../../utils/osa';
import { getLastTimeframe, getParamByLevel } from '../../utils/timeFrameUtils';

import { Breadcrumb } from '../Common/Breadcrumb';
import { DataView } from '../Common/DataView';
import { DirTasksSection } from './DirTasksSection';
import { Header, Loader, Popup, Icon } from 'semantic-ui-react';
import { TabMenu } from '../Common/TabMenu';
import { TimeframeDropdownLimited } from '../Common/TimeframeDropdownLimited';
import { TimeframeDropdownMonLimited } from '../Common/TimeframeDropdownMonLimited';

export const SideKickSkuBayRgn = ({ timeframe, settimeframe, level, fpToggle, locNbr }) => {
  const { currentLevelName, isLoadingName } = useFetchLevelName({ level, locNbr, fpToggle });
  const {
    data: osaSidekickSkuBayData = [],
    isLoading: isLoadingOsaSidekickSkuBay,
    isError: isErrorOsaSidekickSkuBay
  } = useOsaSideKickSkuBayQuery({ level, locNbr, fpToggle });
  const {
    dataAsMap: districtExeDataMap,
    isDataEmpty: isDataEmptySideKickExeScores,
    isError: isErrorSideExeScores,
    isLoading: isLoadingsideKickExeScores
  } = useOsaSideKickExeScoresPlusOneQuery({ level, locNbr, fpToggle });

  if (isErrorOsaSidekickSkuBay || isErrorSideExeScores) return <Header textAlign='center'>Something went wrong</Header>;
  if (isLoadingOsaSidekickSkuBay || isLoadingsideKickExeScores || isLoadingName) return <Loader active>Loading</Loader>;
  if (!osaSidekickSkuBayData.length || isDataEmptySideKickExeScores)
    return <Header textAlign='center'>Invalid Store Number</Header>;

  const ltf = getLastTimeframe(timeframe);
  const urlParam = getParamByLevel(level);

  const increaseLastTimeframeData = (data, row, ltf) => {
    const districtExeData = districtExeDataMap[row.DST_NBR];

    data.totalCompletedTasks += getSkuBayCompletedTasks(districtExeData, timeframe);
    data.totalTasksGoal += getSkuBayTaskGoal(districtExeData, timeframe);
    data.ttlCompLTF += getCompletedTaskCount(row, ltf);
    data.ttlTasksLTF += getTotalTaskCount(row, ltf);
    if (isTaskTypeLogged(row)) data.logSkusLTF += getSkuPackDown(row, ltf);
  };

  const directedTaskSummary = osaSidekickSkuBayData.reduce((acc, row) => {
    if (!acc[row.DST_NBR]) acc[row.DST_NBR] = initDistrictSummaryData();

    const districtExeData = districtExeDataMap[row.DST_NBR];
    acc[row.DST_NBR].completedTasks = getSkuBayCompletedTasks(districtExeData, timeframe);
    acc[row.DST_NBR].taskGoal = getSkuBayTaskGoal(districtExeData, timeframe);

    if (isTaskOriginSidekick(row)) increaseSidekickData(acc[row.DST_NBR], row, timeframe);
    if (isTaskOriginManager(row)) increaseManagerData(acc[row.DST_NBR], row, timeframe);
    if (isTaskTypeLogged(row)) acc[row.DST_NBR].logSkus += getSkuPackDown(row, timeframe);
    if (ltf) increaseLastTimeframeData(acc[row.DST_NBR], row, ltf);

    return acc;
  }, {});

  const overviewData = {
    headers: [
      { name: 'Districts' },
      { name: 'SKU/Bay Execution Score' },
      { name: 'Goal' },
      { name: ltf ? `v${ltf} bps` : '-' }
    ],
    data: Object.entries(directedTaskSummary)
      .map(([districtNbr, data]) => {
        const ltfValue =
          ltf && Math.round((data.totalCompletedTasks / data.totalTasksGoal - data.ttlCompLTF / data.ttlTasksLTF) * 10000);
        return [
          {
            stringValue: districtNbr.padStart(4, '0'),
            link: {
              to: '/osa/sidekickskubay/district',
              params: {
                dstNbr: districtNbr
              }
            }
          },
          {
            compareValue: OSA_SIDEKICK_SKUBAY_GOAL,
            pctValue: data.completedTasks / (data.taskGoal || 1)
          },
          { pctValue: OSA_SIDEKICK_SKUBAY_GOAL, decimals: 1 },
          ltf
            ? {
                compareStyle: true,
                stringValue: ltfValue.toLocaleString(undefined, { maximumFractionDigits: 0 }),
                orderBy: ltfValue,
                postfix: ' bps'
              }
            : { stringValue: '-' }
        ];
      })
      .filter(row => row !== null), // Filter out any null rows
    sortable: true,
    sortableDefault: 0
  };

  const breadcrumbItems = [
    { to: `/${level}/overview?${urlParam}=${locNbr}`, text: 'Overview' },
    {
      to: `/osa?${urlParam}=${locNbr}`,
      text: 'OSA'
    },
    { text: 'SKU/Bay' }
  ];

  const loggedData = {
    data: Object.entries(directedTaskSummary).map(([districtNbr, data]) => {
      return [
        {
          stringValue: districtNbr.padStart(4, '0'),
          link: {
            to: '/osa/sidekickskubay/district',
            params: {
              strNbr: districtNbr
            }
          }
        },
        { stringValue: data.logSkus.toLocaleString(undefined, { maximumFractionDigits: 0 }) },
        {
          stringValue: (data.logSkus - data.logSkusLTF).toLocaleString(undefined, { maximumFractionDigits: 0 }),
          compareStyle: true
        }
      ];
    }),
    headers: [{ name: 'Store' }, { name: 'Logged SKUs #' }, { name: ltf ? `v${ltf} SKU#` : '-' }],
    sortable: true,
    sortableDefault: 0
  };

  const mgrAddData = {
    headers: MGR_ADD_HEADERS,
    data: Object.entries(directedTaskSummary).map(([districtNbr, data]) => {
      return [
        {
          stringValue: districtNbr.padStart(4, '0'),
          link: {
            to: '/osa/sidekickskubay/district',
            params: {
              dstNbr: districtNbr
            }
          }
        },

        { stringValue: data.mgrComp.toLocaleString(undefined, { maximumFractionDigits: 0 }) },
        { stringValue: data.mgrSent.toLocaleString(undefined, { maximumFractionDigits: 0 }) },
        { pctValue: data.mgrComp / data.mgrSent, decimals: 1 }
      ];
    }),
    sortable: true,
    sortableDefault: 0
  };

  const panes = [
    {
      menuItem: 'DIR TASKS',
      render: () => <DirTasksSection directedTaskSummary={directedTaskSummary} />
    },
    {
      menuItem: 'MGR ADD',
      render: () => <DataView.DataTable data={mgrAddData} />
    },
    {
      menuItem: 'LOGGED WK',
      render: () => <DataView.DataTable data={loggedData} />
    }
  ];

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />

      <DataView className='mt-2'>
        <DataView.Title>
          <span>
            Sidekick Execution - SKU/Bay - {level} {locNbr}, {currentLevelName}
            <Popup
              on='click'
              position='bottom left'
              content='Execution Rate= Directed Tasks Completed/Directed Tasks Goal.  Directed Tasks Goals is an estimate of the number of tasks that can be done by the store given the labor hours.'
              trigger={<Icon name={'info circle'} color={'blue'} />}
            />
          </span>
          <Header.Subheader>Data as of {osaSidekickSkuBayData[0]?.TRNSFM_DT}</Header.Subheader>
        </DataView.Title>

        <DataView.Controls className='align-items-center d-flex flex-column'>
          Timeframe:
          {new Date().getDay() === 1 ? (
            <TimeframeDropdownMonLimited timeframe={timeframe} settimeframe={settimeframe} />
          ) : (
            <TimeframeDropdownLimited timeframe={timeframe} settimeframe={settimeframe} />
          )}
        </DataView.Controls>
        <DataView.DataTable data={overviewData} />
      </DataView>

      <DataView className='mt-3'>
        <DataView.Title>Tasks Completion</DataView.Title>

        <TabMenu panes={panes} />
      </DataView>
    </>
  );
};

const MGR_ADD_HEADERS = [
  { name: 'District' },
  { name: 'Ttl Tasks Comp #' },
  { name: 'Ttl Tasks Added #' },
  { name: 'Ttl Tasks Comp %' }
];
