import { DataTable } from '../Common/DataTable';
import { Grid, Header, Icon, Popup } from 'semantic-ui-react';
import { OSA_SIDEKICK_PURGE_GOAL } from '../../constants/goals';

export const SidekickExcecutionPurge = ({ ltf, data, level, findItem, timeframe, dataScores, entityField }) => {
  const sideKickPurgeData = data.reduce((acc, curr) => {
    const sideExeData = dataScores?.find(r => r[entityField] === curr[entityField]);

    if (!sideExeData) return acc;

    let item = findItem(acc, entityField, curr);

    if (item) {
      updateItem(item, curr, timeframe, sideExeData, ltf);
    } else {
      acc.push(createNewItem(entityField, curr, timeframe, sideExeData, ltf));
    }

    return acc;
  }, []);

  const dataObj = {
    headers: [
      { name: level === 'district' ? 'Store' : 'District' },
      { name: 'Actual' },
      { name: 'Goal' },
      { name: `v${ltf} bps` }
    ],
    data: sideKickPurgeData.map(data => [
      {
        stringValue: data[entityField],
        link: {
          to: `/osa/Purge/${level === 'district' ? 'store' : 'district'}`,
          params: level === 'district' ? { strNbr: data[entityField] } : { dstNbr: data[entityField] }
        }
      },
      {
        pctValue: data.ACTUAL,
        customStyle: { color: data.ACTUAL < OSA_SIDEKICK_PURGE_GOAL ? 'red' : 'green' }
      },
      {
        pctValue: OSA_SIDEKICK_PURGE_GOAL
      },
      {
        numValue:
          data.BPS === '-' || isNaN(data.BPS)
            ? '-'
            : ((data.ACTUAL - data.BPS) * 10000).toLocaleString(undefined, { maximumFractionDigits: 0 })
      }
    ]),
    sortable: true,
    sortableDefault: 0
  };

  return (
    <>
      <Grid centered style={{ marginBottom: '1rem' }}>
        <Grid.Column textAlign='center'>
          <Header as='h3'>
            Sidekick Execution - Purge
            <Popup
              trigger={<Icon name='info circle' color='blue' />}
              content='Execution Rate = Purge Tasks Completed/60. A store is given 60 purge tasks to execute each week.'
              position='right center'
              on='click'
              textAlign='center'
            />
          </Header>
        </Grid.Column>
      </Grid>

      <DataTable data={dataObj} />
    </>
  );
};

const createNewItem = (entityField, curr, timeframe, sideExeData, ltf) => {
  const totalTaskCount = curr[timeframe + '_TOTAL_TASK_COUNT'];
  const completedTaskCount = sideExeData[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT'];
  const actualPurgeRate = completedTaskCount / totalTaskCount;

  const prevTotalTaskCount = curr[ltf + '_TOTAL_TASK_COUNT'];
  const prevCompletedTaskCount = sideExeData[ltf + '_STR_PURGE_COMPLETED_TASK_COUNT'];
  const bps = prevTotalTaskCount !== 0 ? prevCompletedTaskCount / prevTotalTaskCount : '-';

  return {
    [entityField]: curr[entityField],
    [timeframe + '_TASK_COUNT']: totalTaskCount,
    [timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT']: completedTaskCount,
    ACTUAL: actualPurgeRate,
    [ltf + '_STR_PURGE_TASK_COUNT']: curr[ltf + '_STR_PURGE_TASK_COUNT'],
    [ltf + '_STR_PURGE_COMPLETED_TASK_COUNT']: prevCompletedTaskCount,
    BPS: bps
  };
};

const updateItem = (item, curr, timeframe, sideExeData, ltf) => {
  const totalTaskCount = curr[timeframe + '_TOTAL_TASK_COUNT'];
  const completedTaskCount = sideExeData[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT'];
  const prevTotalTaskCount = curr[ltf + '_TOTAL_TASK_COUNT'];
  const prevCompletedTaskCount = sideExeData[ltf + '_STR_PURGE_COMPLETED_TASK_COUNT'];

  item[timeframe + '_TASK_COUNT'] += totalTaskCount;
  item[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT'] = completedTaskCount;
  item.ACTUAL = item[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT'] / item[timeframe + '_TOTAL_TASK_COUNT'];

  item[ltf + '_LY_STR_PURGE_TASK_COUNT'] += curr[ltf + '_LY_STR_PURGE_TASK_COUNT'];
  item[ltf + '_STR_PURGE_COMPLETED_TASK_COUNT'] = prevCompletedTaskCount;

  item.BPS = prevTotalTaskCount !== 0 ? prevCompletedTaskCount / prevTotalTaskCount : '-';
};
