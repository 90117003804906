import React, {useState, useEffect} from 'react'
import {
    Grid,
    Header,
    Loader,
    Menu,
    Segment,
    Modal,
    Icon,
  } from 'semantic-ui-react'
import { withRouter, Link } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery } from 'react-query'
import { DataTable } from '../Common/DataTable'
import { getPercentString } from "../Common/TableCellUtils";
import {
    getData
  } from '../../service/DataService'

  import { TimeframeDropdownLimited } from '../Common/TimeframeDropdownLimited'
  import { TimeframeDropdownMonLimited } from '../Common/TimeframeDropdownMonLimited'
import { OSA_SIDEKICK_SKUBAY_GOAL } from '../../constants/goals'

export const SideKickSkuBay = withRouter(({ timeframe, settimeframe, level, fpToggle, locNbr, location }) => {
  
  const search = new URLSearchParams(location.search)
  const active = search.has('active') ? search.get('active') : "DIR TASKS"

  const [open, setOpen] = useState(false)
  const [drilldownTab, setdrilldownTab] = useState("TOTAL")
  const [tasksBreakdownTab, settasksBreakdownTab] = useState(active)

  useEffect(() => {
    if (timeframe !== 'WTD' && timeframe !== 'LW') {
      settimeframe('WTD')
    }
  }, [timeframe])
  let currentDay = new Date().getDay();
  let displayTimeframe = timeframe;
  if (currentDay === 1) { // 1 represents Monday
    if (timeframe === 'WTD') {
      displayTimeframe = 'LW';
    } else if (timeframe === 'LW') {
      displayTimeframe = 'LLW';
    }
  }

  const osaSidekickSkuBayQuery = useQuery([`query/queryOSASidekickSkuBay/${level}`, { strNbr:locNbr, fpToggle }], getData)
  const osaSidekickSkuBayDateDtlQuery = useQuery([`queryOSASidekickSkuBayDateDtlBreakDown`, { strNbr: locNbr, fpToggle }], getData)
  const {
    data: sideExeData = [],
    isLoading: sideExeLoading
  } = useQuery([`query/sidekickExeScoresPlusOne/${level}`, { strNbr:locNbr, fpToggle }], getData);
  
  
  if (osaSidekickSkuBayQuery.isLoading || osaSidekickSkuBayDateDtlQuery.isLoading || sideExeLoading) { 
    return (<Loader active>Loading</Loader>)
  }

  if (
      (!osaSidekickSkuBayQuery.data || osaSidekickSkuBayQuery.data.length === 0) 
      || (!osaSidekickSkuBayDateDtlQuery.data || osaSidekickSkuBayDateDtlQuery.data.length === 0)
      || (!sideExeData || sideExeData.length === 0)
    ) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  const osaSidekickSkuBayData =  osaSidekickSkuBayQuery.data.filter(({DEPT}) => DEPT !== '38')
  const osaSidekickSkuBayDateDtlData = osaSidekickSkuBayDateDtlQuery.data.filter(({DEPT_NBR}) => DEPT_NBR !== 38)

  const storeSummaries = [];

if (Array.isArray(sideExeData)) {
  sideExeData.map(store => {
    const storeSummary = {
      STR_NBR: store.STR_NBR,
      tasksSkuBayCompleted: store[`${timeframe}_STR_SKU_BAY_COMPLETED_TASK_COUNT`] ?? 0,
      tasksSkuBayCompletedGoal: store[`${timeframe}_STR_SKU_BAY_TASK_GOAL`] ?? 0,
      tasksTotalCompletedGoal: (store[`${timeframe}_STR_SKU_BAY_TASK_GOAL`] ?? 0) + (store[`${timeframe}_STR_PURGE_TASK_GOAL`] ?? 0),
      tasksSkuBayEXERT: store[`${timeframe}_STR_SKU_BAY_COMPLETED_TASK_COUNT`] / store[`${timeframe}_STR_SKU_BAY_TASK_GOAL`],
    };
    storeSummaries.push(storeSummary);
  });
}


  const directedTaskSummary = osaSidekickSkuBayData
  .filter(row => row.TASK_TYPE === 'SKU' || row.TASK_TYPE === 'BAY')
  .reduce((acc, row) => {
    acc.skuComp += row.TASK_TYPE === 'SKU' ? row[`${timeframe}_COMPLETED_TASK_COUNT`] : 0
    acc.skuSent += row.TASK_TYPE === 'SKU' ? row[`${timeframe}_TOTAL_TASK_COUNT`] : 0
    acc.skuCompPt += row.TASK_TYPE === 'SKU' ? row[`${timeframe}_COMPLETED_POINTS`] : 0
    acc.skuTtlPt += row.TASK_TYPE === 'SKU' ? row[`${timeframe}_TOTAL_POINTS`] : 0
    acc.bayComp += row.TASK_TYPE === 'BAY' ? row[`${timeframe}_COMPLETED_TASK_COUNT`] : 0
    acc.baySent += row.TASK_TYPE === 'BAY' ? row[`${timeframe}_TOTAL_TASK_COUNT`] : 0
    acc.bayCompPt += row.TASK_TYPE === 'BAY' ? row[`${timeframe}_COMPLETED_POINTS`] : 0
    acc.bayTtlPt += row.TASK_TYPE === 'BAY' ? row[`${timeframe}_TOTAL_POINTS`] : 0
    acc.ttlComp += row[`${timeframe}_COMPLETED_TASK_COUNT`]
    acc.ttlTasks += row[`${timeframe}_TOTAL_TASK_COUNT`]
    acc.ttlCompPt += (row.TASK_TYPE === 'SKU' ? row[`${timeframe}_COMPLETED_POINTS`] : 0) + (row.TASK_TYPE === 'BAY' ? row[`${timeframe}_COMPLETED_POINTS`] : 0)
    acc.TtlPt += (row.TASK_TYPE === 'SKU' ? row[`${timeframe}_TOTAL_POINTS`] : 0) + (row.TASK_TYPE === 'BAY' ? row[`${timeframe}_TOTAL_POINTS`] : 0)
    return acc
  }, {skuComp: 0, skuSent: 0, skuCompPt: 0, skuTtlPt: 0,  bayComp: 0, baySent: 0, bayCompPt: 0, bayTtlPt: 0, ttlComp: 0, ttlTasks: 0, ttlCompPt: 0, TtlPt: 0})

  const EXE_RT = storeSummaries.reduce((acc, store) => acc + store.tasksSkuBayCompleted, 0) / storeSummaries.reduce((acc, store) => acc + store.tasksSkuBayCompletedGoal, 0)
  const storeSummary = {
    tasksTotalCompletedGoal: sideExeData[`${timeframe}_STR_SKU_BAY_TASK_GOAL`] + sideExeData[`${timeframe}_STR_PURGE_TASK_GOAL`]
  };
  const directedTaskData = {
    
    headers: [
      {name: 'Ttl Tasks Comp #'}, 
      {
        name: 'Tasks Goal',
        popup: {
          content:"Directed Tasks Goals is an estimate of the number of tasks that can be done by the store given the labor hours.",
          icon: "info circle",
          color: "blue"
        }

      },
      {name: 'Execution Rate'}, {name: 'Tasks Sent #'}, {name: 'Task Goal %'}
    ],
    data: [
      [
        {numValue: storeSummaries.reduce((acc, store) => acc + store.tasksSkuBayCompleted, 0)},
        {numValue: storeSummaries.reduce((acc, store) => acc + store.tasksSkuBayCompletedGoal, 0)}, 
        {pctValue: 
           storeSummaries.reduce((acc, store) => acc + store.tasksSkuBayCompleted, 0) / storeSummaries.reduce((acc, store) => acc + store.tasksSkuBayCompletedGoal, 0),
           compareValue: OSA_SIDEKICK_SKUBAY_GOAL
        },
        {numValue: directedTaskSummary.ttlTasks},
           
        {pctValue: OSA_SIDEKICK_SKUBAY_GOAL}
      ], 
    ]
  }

  const managerTaskSummary = osaSidekickSkuBayData
  .filter(row => row.TASK_ORIGIN === 'MANAGER')
  .reduce((acc, row) => {
    acc.ttlComp += row[`${timeframe}_COMPLETED_TASK_COUNT`]
    acc.ttlTasks += row[`${timeframe}_TOTAL_TASK_COUNT`]
    return acc
  }, {ttlComp: 0, ttlTasks: 0})

  const managerAddedBaysData = {
    headers: [
      {name: 'Ttl Tasks Comp #'}, {name: 'Ttl Tasks Added #'}, {name: 'Ttl Tasks Comp %'}
    ],
    data: [
      [
        {numValue: managerTaskSummary.ttlComp}, 
        {numValue: managerTaskSummary.ttlTasks},
        {
          pctValue: managerTaskSummary.ttlComp / managerTaskSummary.ttlTasks, decimals: 1
        }
      ], 
    ]
  }

  const loggedWorkSummary = osaSidekickSkuBayData
  .filter(row => row.TASK_TYPE === 'LOGGED')
  .reduce((acc, row) => {
    acc.total += row[`${timeframe}_SKUS_PACKED_DOWN`]
    return acc
  }, {total: 0})

  const loggedWorkData = {
    headers: [],
    data: [
      [
        {stringValue: 'Logged SKUs'}, 
        {numValue: loggedWorkSummary.total, postfix: ' SKUs'}
      ], 
    ]
  }
  const weekDetail = osaSidekickSkuBayDateDtlData
  .filter(({WTD_FLG, LW_FLG}) => timeframe === 'WTD' ? WTD_FLG === 1 : LW_FLG === 1)
  
  const dailyData = weekDetail
  .reduce((acc, row) => {

    const [year, month, day] = row.CREATED_DT.split('-').map(Number);
    const date = new Date(year, month - 1, day); 
    const dayOfWeek = date.getDay()
    acc[dayOfWeek].dir += row.TASK_TYPE === 'SKU' || row.TASK_TYPE === 'BAY' ? row.TOTAL_TASK_COUNT : 0
    acc[dayOfWeek].com += row.TASK_TYPE === 'SKU' || row.TASK_TYPE === 'BAY' ? row.COMPLETED_TASK_COUNT : 0
    acc[dayOfWeek].mgr += row.TASK_ORIGIN === 'MANAGER' ? row.TOTAL_TASK_COUNT : 0
    acc[dayOfWeek].mgrCom += row.TASK_ORIGIN === 'MANAGER' ? row.COMPLETED_TASK_COUNT : 0
    return acc
  }, [
    {dir:0,mgr:0,com:0,mgrCom:0},{dir:0,mgr:0,com:0,mgrCom:0},{dir:0,mgr:0,com:0,mgrCom:0},
    {dir:0,mgr:0,com:0,mgrCom:0},{dir:0,mgr:0,com:0,mgrCom:0},{dir:0,mgr:0,com:0,mgrCom:0},
    {dir:0,mgr:0,com:0,mgrCom:0}
  ])

  const directedTasksBreakdownData = {
    headers: [
      {name: ''},{name: 'M'},{name: 'T'},
      {name: 'W'},{name: 'T'},{name: 'F'},
      {name: 'S'},{name: 'S'},
    ],
    data: [
      [
        {stringValue: 'DIR'}, 
        {numValue: dailyData[1].dir ? dailyData[1].dir : '-'},
        {numValue: dailyData[2].dir ? dailyData[2].dir : '-'},
        {numValue: dailyData[3].dir ? dailyData[3].dir : '-'},
        {numValue: dailyData[4].dir ? dailyData[4].dir : '-'},
        {numValue: dailyData[5].dir ? dailyData[5].dir : '-'},
        {numValue: dailyData[6].dir ? dailyData[6].dir : '-'},
        {numValue: dailyData[0].dir ? dailyData[0].dir : '-'},
      ], 
      [
        {stringValue: 'COM'}, 
        {numValue: dailyData[1].com ? dailyData[1].com : '-'},
        {numValue: dailyData[2].com ? dailyData[2].com : '-'},
        {numValue: dailyData[3].com ? dailyData[3].com : '-'},
        {numValue: dailyData[4].com ? dailyData[4].com : '-'},
        {numValue: dailyData[5].com ? dailyData[5].com : '-'},
        {numValue: dailyData[6].com ? dailyData[6].com : '-'},
        {numValue: dailyData[0].com ? dailyData[0].com : '-'},
      ], 
    ]
  }
  const mgrAddTasksBreakdownData = {
    headers: [
      {name: ''},{name: 'M'},{name: 'T'},
      {name: 'W'},{name: 'T'},{name: 'F'},
      {name: 'S'},{name: 'S'},
    ],
    data: [
      [
        {stringValue: 'MGR'}, 
        {numValue: dailyData[1].mgr ? dailyData[1].mgr : '-'},
        {numValue: dailyData[2].mgr ? dailyData[2].mgr : '-'},
        {numValue: dailyData[3].mgr ? dailyData[3].mgr : '-'},
        {numValue: dailyData[4].mgr ? dailyData[4].mgr : '-'},
        {numValue: dailyData[5].mgr ? dailyData[5].mgr : '-'},
        {numValue: dailyData[6].mgr ? dailyData[6].mgr : '-'},
        {numValue: dailyData[0].mgr ? dailyData[0].mgr : '-'},
      ], 
      [
        {stringValue: 'COM'}, 
        {numValue: dailyData[1].mgrCom ? dailyData[1].mgrCom : '-'},
        {numValue: dailyData[2].mgrCom ? dailyData[2].mgrCom : '-'},
        {numValue: dailyData[3].mgrCom ? dailyData[3].mgrCom : '-'},
        {numValue: dailyData[4].mgrCom ? dailyData[4].mgrCom : '-'},
        {numValue: dailyData[5].mgrCom ? dailyData[5].mgrCom : '-'},
        {numValue: dailyData[6].mgrCom ? dailyData[6].mgrCom : '-'},
        {numValue: dailyData[0].mgrCom ? dailyData[0].mgrCom : '-'},
      ], 
    ]
  }

  const dirTasksTotalSummary = weekDetail
  .filter(({TASK_ORIGIN, TASK_TYPE}) => TASK_ORIGIN === 'SIDEKICK' && (TASK_TYPE === 'SKU' || TASK_TYPE === 'BAY'))
  .reduce((acc, row) => {
    if (!acc[row.DEPT_NBR]) {
      acc[row.DEPT_NBR] = {DEPT_NBR: row.DEPT_NBR, DEPT_NM: row.DEPT_NM, TOTAL_TASK_COUNT: 0, COMPLETED_TASK_COUNT: 0}
    }
    acc[row.DEPT_NBR].DEPT_NBR = row.DEPT_NBR
    acc[row.DEPT_NBR].DEPT_NM = row.DEPT_NM
    acc[row.DEPT_NBR].TOTAL_TASK_COUNT += 1 
    acc[row.DEPT_NBR].COMPLETED_TASK_COUNT += row.COMPLETED_TASK_COUNT
    return acc
  }, {})

  const dirTasksTtlData = {
    sortable: true,
    headers: [
      {name: 'Dept'}, {name: 'Tasks Comp #'}, {name: 'Tasks Sent #'}, {name: 'Tasks Comp %'}
    ],
    data: Object.entries(dirTasksTotalSummary).map(([bay, row]) => {
      return [
        {
          stringValue: row.DEPT_NBR +' '+ row.DEPT_NM,
          link: {
            to: `/osa/sidekickskubay/dept`,
            params: {
              strNbr: locNbr,
              deptNbr: row.DEPT_NBR,
            }
          }
        }, 
        {numValue: row.COMPLETED_TASK_COUNT}, 
        {numValue: row.TOTAL_TASK_COUNT},
        {pctValue: row.COMPLETED_TASK_COUNT / row.TOTAL_TASK_COUNT}, 
      ]
    })
  }

  const dirTasksSKUSummary = weekDetail
  .filter(({TASK_TYPE}) => TASK_TYPE === 'SKU')
  .reduce((acc, row) => {
    if (!acc[row.DEPT_NBR]) {
      acc[row.DEPT_NBR] = {DEPT_NBR: row.DEPT_NBR, DEPT_NM: row.DEPT_NM, TOTAL_TASK_COUNT: 0, COMPLETED_TASK_COUNT: 0}
    }
    acc[row.DEPT_NBR].DEPT_NBR = row.DEPT_NBR
    acc[row.DEPT_NBR].DEPT_NM = row.DEPT_NM
    acc[row.DEPT_NBR].TOTAL_TASK_COUNT += 1 
    acc[row.DEPT_NBR].COMPLETED_TASK_COUNT += row.COMPLETED_TASK_COUNT
    return acc
  }, {})

  const dirTasksSKUData = {
    sortable: true,
    headers: [
      {name: 'Dept'}, {name: 'SKU Tasks Comp #'}, {name: 'SKU Tasks Sent #'}, {name: 'SKU Tasks Comp %'}
    ],
    data: Object.entries(dirTasksSKUSummary).map(([bay, row]) => {
      return [
        {
          stringValue: row.DEPT_NBR +' '+ row.DEPT_NM,
          link: {
            to: `/osa/sidekickskubay/dept`,
            params: {
              strNbr: locNbr,
              deptNbr: row.DEPT_NBR,
            }
          }
        }, 
        {numValue: row.COMPLETED_TASK_COUNT}, 
        {numValue: row.TOTAL_TASK_COUNT},
        {pctValue: row.COMPLETED_TASK_COUNT / row.TOTAL_TASK_COUNT}, 
      ]
    })
  }

  const dirTasksBaySummary = weekDetail
  .filter(({TASK_TYPE}) => TASK_TYPE === 'BAY')
  .reduce((acc, row) => {
    if (!acc[row.DEPT_NBR]) {
      acc[row.DEPT_NBR] = {DEPT_NBR: row.DEPT_NBR, DEPT_NM: row.DEPT_NM, TOTAL_TASK_COUNT: 0, COMPLETED_TASK_COUNT: 0}
    }
    acc[row.DEPT_NBR].DEPT_NBR = row.DEPT_NBR
    acc[row.DEPT_NBR].DEPT_NM = row.DEPT_NM
    acc[row.DEPT_NBR].TOTAL_TASK_COUNT += 1 
    acc[row.DEPT_NBR].COMPLETED_TASK_COUNT += row.COMPLETED_TASK_COUNT
    return acc
  }, {})

  const dirTasksBayData = {
    sortable: true,
    headers: [
      {name: 'Dept'}, {name: 'Bay Tasks Comp #'}, {name: 'Bay Tasks Sent #'}, {name: 'Bay Tasks Comp %'}
    ],
    data: Object.entries(dirTasksBaySummary).map(([bay, row]) => {
      return [
        {
          stringValue: row.DEPT_NBR +' '+ row.DEPT_NM,
          link: {
            to: `/osa/sidekickskubay/dept`,
            params: {
              strNbr: locNbr,
              deptNbr: row.DEPT_NBR,
            }
          }
        }, 
        {numValue: row.COMPLETED_TASK_COUNT}, 
        {numValue: row.TOTAL_TASK_COUNT},
        {pctValue: row.COMPLETED_TASK_COUNT / row.TOTAL_TASK_COUNT}, 
      ]
    })
  }

  let bottomData = null
  switch(drilldownTab) {
    case 'SKU': 
      bottomData = dirTasksSKUData; break;
    case 'BAY':
      bottomData = dirTasksBayData; break;
    default:
      bottomData = dirTasksTtlData
  }
 
  return (
    <>
    <Segment>
    <Grid>
      <Grid.Column width={8}>
        <Grid.Row>
          <Link style={{ color: '#EE7125' }} to={`/${level}/overview?strNbr=${locNbr}`} data-test='b2o-link'>Overview</Link>
          {' / '}
          <Link style={{ color: '#EE7125' }} to={`/osa?strNbr=${locNbr}`} data-test='b2osa-link'>OSA</Link>
          {` / SKU/Bay`}
        </Grid.Row>
        <Grid.Row>&nbsp;</Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
                        Timeframe: 
              {new Date().getDay() === 1 ?
                <TimeframeDropdownMonLimited timeframe={timeframe} settimeframe={settimeframe} /> :
                <TimeframeDropdownLimited timeframe={timeframe} settimeframe={settimeframe} />}
      </Grid.Column>
    </Grid>

    <Header textAlign='center'>Sidekick SKU/Bay Overview</Header>

    <div style={{textAlign:"center"}} >
        <p style={{ display: 'inline-block'}}>Execution Rate: <span style={{ color: EXE_RT > OSA_SIDEKICK_SKUBAY_GOAL ? 'green' : 'red'}}>{getPercentString(EXE_RT)}</span></p>
        <p style={{ display: 'inline-block' }}>/ Goal {getPercentString(OSA_SIDEKICK_SKUBAY_GOAL)} {<Icon name='info circle' color='blue' onClick={()=> { setOpen(true)}} />}</p>   
    </div>     

      <Modal closeIcon open={open} onClose={() => { setOpen(false) }}>
          <Segment>
              <Header textAlign='center'>What does it mean?</Header>
              <p>Execution Rate:</p>
              <p>Directed Tasks Completed/Directed Tasks Goal. Directed Tasks Goals is an estimate of the number of tasks that can be done by the store given the labor hours.</p>
              <p style={{ color: '#3366CC', textDecoration: 'underline', textAlign: 'right' }} onClick={() => { setOpen(false) }}>OK</p>
              &nbsp;
          </Segment>
        </Modal>

    Directed Tasks
    <DataTable data={directedTaskData} />

    <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/osa/manageraddedbays?strNbr=${locNbr}`}>Manager Added Bays</Link>
    <DataTable data={managerAddedBaysData} />
    
    <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/osa/sidekickskubay/logged?strNbr=${locNbr}`}>Logged Work</Link>
    <DataTable data={loggedWorkData} />

    <Header textAlign='center' >{`Tasks breakdown by ${displayTimeframe}`}</Header>
    Tasks<br />
    <Menu compact>
      <Menu.Item
        name='DIR TASKS'
        active={tasksBreakdownTab === 'DIR TASKS'}
        content='DIR TASKS'
        onClick={() => {
          settasksBreakdownTab('DIR TASKS')
        }}
      />
      <Menu.Item
        name='MGR ADD'
        active={tasksBreakdownTab === 'MGR ADD'}
        content='MGR ADD'
        onClick={() => {
          settasksBreakdownTab('MGR ADD')
        }}
      />
    </Menu>
    <DataTable data={tasksBreakdownTab === 'DIR TASKS' ? directedTasksBreakdownData : mgrAddTasksBreakdownData} />
    {
        tasksBreakdownTab === 'DIR TASKS' ?
            'DIR: Directed tasks sent by Sidekick by day' : 'MGR: Manager added bays by day'
    } <br />
    COM: Completed tasks by day
  
    <Header textAlign='center'>Directed Tasks Completion</Header>

    <Menu compact>
      <Menu.Item
        name='TOTAL'
        active={drilldownTab === 'TOTAL'}
        content='TOTAL'
        onClick={() => {
          setdrilldownTab('TOTAL')
        }}
      />
      <Menu.Item
        name='SKU'
        active={drilldownTab === 'SKU'}
        content='SKU'
        onClick={() => {
          setdrilldownTab('SKU')
        }}
      />
      <Menu.Item
        name='BAY'
        active={drilldownTab === 'BAY'}
        content='BAY'
        onClick={() => {
          setdrilldownTab('BAY')
        }}
      />
    </Menu>

    <DataTable data={bottomData} />
    </Segment>
    </>
)
})
