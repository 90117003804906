import { useState } from 'react';
import { OSA_SIDEKICK_SKUBAY_GOAL } from '../../constants/goals';
import { Popup, Icon } from 'semantic-ui-react';
import { MenuItems } from '../Common/MenuItems';
import { DataTable } from '../Common/DataTable';

export const DirTasksSection = ({ directedTaskSummary }) => {
  const [skuBaysOption, setSkuBaysOption] = useState('TOTAL');
  const isTotalSelected = skuBaysOption === 'TOTAL';
  const isSkuSelected = skuBaysOption === 'SKU';

  const headers = isTotalSelected ? DIR_TASK_DEFAULT_HEADERS : getDirTasksSkuHeaders(isSkuSelected);

  const dataObj = {
    headers,
    data: Object.entries(directedTaskSummary).map(([districtNbr, data]) => {
      const dataPrefix = isTotalSelected ? 'skuBay' : isSkuSelected ? 'sku' : 'bay';

      const linkObject = {
        stringValue: districtNbr.padStart(4, '0'),
        link: {
          to: '/osa/sidekickskubay/district',
          params: {
            dstNbr: districtNbr
          }
        }
      };

      if (isTotalSelected)
        return [
          { ...linkObject },
          { stringValue: data.completedTasks.toLocaleString(undefined, { maximumFractionDigits: 0 }) },
          { stringValue: data.taskGoal.toLocaleString(undefined, { maximumFractionDigits: 0 }) },
          { pctValue: data.completedTasks / data.taskGoal, decimals: 1 },
          { stringValue: data[`${dataPrefix}Sent`].toLocaleString(undefined, { maximumFractionDigits: 0 }) },
          { pctValue: OSA_SIDEKICK_SKUBAY_GOAL }
        ];

      return [
        { ...linkObject },
        { stringValue: data[`${dataPrefix}Comp`].toLocaleString(undefined, { maximumFractionDigits: 0 }) },
        { stringValue: data[`${dataPrefix}Sent`].toLocaleString(undefined, { maximumFractionDigits: 0 }) },
        { pctValue: data[`${dataPrefix}Comp`] / data[`${dataPrefix}Sent`], decimals: 1 }
      ];
    }),
    sortable: true,
    sortableColumn: 0
  };

  return (
    <>
      <MenuItems
        activeItem={skuBaysOption}
        className='mt-3'
        dataTest='skuBaysOption'
        items={['TOTAL', 'SKU', 'BAY']}
        setItem={setSkuBaysOption}
      />

      <DataTable data={dataObj} />
    </>
  );
};

const DIR_TASK_DEFAULT_HEADERS = [
  { name: 'District ' },
  { name: 'Tasks Comp # ' },
  { name: 'Tasks Goal ' },
  {
    element: (
      <span>
        Execution Rate %
        <Popup
          on='click'
          position='bottom left'
          content='Execution Rate= Directed Tasks Completed/Directed Tasks Goal
          Directed Tasks Goals is an estimate of the number of tasks that can be done by the store given the labor hours.'
          trigger={<Icon name='info circle' color='blue' />}
        />
      </span>
    )
  },
  { name: 'Tasks Sent #' },
  { name: 'Tasks Goal %' }
];

const getDirTasksSkuHeaders = isSku => {
  const headerPrefix = isSku ? 'SKU' : 'Bay';

  return [
    { name: 'District' },
    { name: `${headerPrefix} Tasks Comp #` },
    { name: `${headerPrefix} Tasks Sent #` },
    { name: `${headerPrefix} Tasks Comp %` }
  ];
};
