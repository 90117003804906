import React, { useEffect } from 'react';
import { PurgePackdown } from './PurgePackdown';
import useQueryApi from '../../hooks/useQueryApi';
import { useQueryParams } from '../../hooks/useQueryParams';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Header, Loader, Grid, Segment } from 'semantic-ui-react';
import { SidekickExcecutionPurge } from './SidekickExcecutionPurge';
import { TimeframeDropdownLimited } from '../Common/TimeframeDropdownLimited';
import { TimeframeDropdownMonLimited } from '../Common/TimeframeDropdownMonLimited';

const NIGHT_CREW_DEPT = '38';

export const SideKickPurgePage = ({ timeframe, settimeframe, level, fpToggle, locNbr }) => {
  const { dstNbr = 0, rgnNbr = 0 } = useQueryParams();
  const entityField = level === 'district' ? 'STR_NBR' : 'DST_NBR';

  useEffect(() => {
    if (timeframe !== 'WTD' && timeframe !== 'LW') {
      settimeframe('WTD');
    }
  }, [timeframe]);

  const param = level === 'district' ? { dstNbr, fpToggle } : { locNbr: rgnNbr, fpToggle };
  const purgeData = useQueryApi(`query/queryOSASidekickSkuBayDst/${level}`, param);
  const exeScores = useQueryApi(`query/sidekickExeScoresPlusOne/${level}`, param);

  if (purgeData.isLoading || exeScores.isLoading) return <Loader active>Loading</Loader>;
  if (purgeData.error || exeScores.error)
    return <Header textAlign='center'>Error loading purge data o execution scores</Header>;
  if (!purgeData.data || purgeData.data.length === 0) return <Header textAlign='center'>Invalid Store Number</Header>;

  const purgeDataByNightCrewDept = purgeData.data.filter(({ DEPT }) => DEPT === NIGHT_CREW_DEPT);
  let osaSideKickPurgeDstDataFilterByPurge = purgeDataByNightCrewDept.filter(row => row['TASK_TYPE'] === 'PURGE');

  const lastTimeframeMap = { WTD: 'LW', LW: null };
  const ltf = lastTimeframeMap[timeframe];

  return (
    <>
      <Segment>
        <Grid>
          <Grid.Column width={8}>
            <Grid.Row>
              <Link
                style={{ color: '#EE7125' }}
                to={`/${level}/overview?${level === 'district' ? 'dstNbr' : 'rgnNbr'}=${locNbr}`}
                data-test='b2o-link'>
                Overview
              </Link>
              {' / '}
              <Link
                style={{ color: '#EE7125' }}
                to={`/osa?${level === 'district' ? 'dstNbr' : 'rgnNbr'}=${locNbr}`}
                data-test='b2osa-link'>
                OSA
              </Link>
              {' / Purge'}
            </Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
          </Grid.Column>

          <Grid.Column width={7} />
          <Grid.Column width={7}>
            Timeframe:
            {new Date().getDay() === 1 ? (
              <TimeframeDropdownMonLimited timeframe={timeframe} settimeframe={settimeframe} />
            ) : (
              <TimeframeDropdownLimited timeframe={timeframe} settimeframe={settimeframe} />
            )}
          </Grid.Column>
        </Grid>

        <SidekickExcecutionPurge
          ltf={ltf}
          level={level}
          findItem={findItem}
          timeframe={timeframe}
          entityField={entityField}
          dataScores={exeScores.data}
          data={osaSideKickPurgeDstDataFilterByPurge}
        />

        <PurgePackdown
          level={level}
          findItem={findItem}
          timeframe={timeframe}
          entityField={entityField}
          dataScores={exeScores.data}
          data={osaSideKickPurgeDstDataFilterByPurge}
        />
      </Segment>
    </>
  );
};

const findItem = (acc, entityField, curr) => acc.find(item => item[entityField] === curr[entityField]);
