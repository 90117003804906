import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { OnlineVoc } from './Region/OnlineVoc';
import { getData } from '../../service/DataService';
import { useQueryParams } from '../../hooks/useQueryParams';
import { VocDetailsComponent } from './VocDetailsComponent';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { Loader, Grid, Dropdown, Segment } from 'semantic-ui-react';
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon';

const METRIC_CONSTANTS = {
  store: { // There's a bug where the level stays 'store' when hitting the back button, so this compensates
    query: 'queryOnlineVocSmryDstByDst',
    METRIC_LEVEL: 'DST',
    urlParam: 'dstNbr'
  },
  district: {
    query: 'queryOnlineVocSmryDstByDst',
    METRIC_LEVEL: 'DST',
    urlParam: 'dstNbr'
  },
  region: {
    query: 'queryOnlineVocSmryDstByRgn',
    METRIC_LEVEL: 'RGN',
    urlParam: 'rgnNbr'
  }
};

const ONLINE_VOC_QUERIES = {
  store: { // There's a bug where the level stays 'store' when hitting the back button, so this compensates
    urlParam: 'dstNbr',
    METRIC_LEVEL: 'DST',
    query: 'queryOnlineVocSmryStrByDst'
  },
  district: {
    urlParam: 'dstNbr',
    METRIC_LEVEL: 'DST',
    query: 'queryOnlineVocSmryStrByDst'
  },
  region: {
    urlParam: 'rgnNbr',
    METRIC_LEVEL: 'RGN',
    query: 'queryOnlineVocSmryDstByRgn'
  }
};

export const VocDetailDst = ({ timeframe, settimeframe, fpToggle, level, levelName }) => {
  const [metric, setMetric] = useState('LTPA');
  const { dstNbr = 0, rgnNbr = 0 } = useQueryParams();
  const queryParams = level === 'district' ? { dstNbr } : { locNbr: rgnNbr };
  const vocQuery = useQuery([METRIC_CONSTANTS[level]['query'], { ...queryParams, fpToggle }], getData);
  const onlineVocQuery = useQuery([ONLINE_VOC_QUERIES[level]['query'], { ...queryParams, fpToggle }], getData);

  if (vocQuery.isLoading || onlineVocQuery.isLoading) {
    return <Loader active>Loading...</Loader>;
  }

  let overviewDataFilter = vocQuery.data.filter(row => {
    return row && row['TIME_FRAME'] === timeframe;
  });

  return (
    <Segment fluid>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8} textAlign='center'>
            <Grid.Row>&nbsp;</Grid.Row>
            {
              <Link
                style={{ color: '#EE7125' }}
                to={`/${level}/overview?${METRIC_CONSTANTS[level]['urlParam']}=${dstNbr || rgnNbr}`}
                data-test='b2o-link'>
                Back to Overview
              </Link>
            }
          </Grid.Column>
          <Grid.Column width={8}>
            Timeframe:
            {new Date().getDay() === 1 ? (
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} />
            ) : (
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <VocDetailsComponent
        dataFilter={overviewDataFilter}
        level={level}
        levelNumber={rgnNbr || dstNbr}
        levelName={levelName}
      />
      &nbsp;
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            Select Metric:
            <Dropdown
              fluid
              compact
              selection
              defaultValue='LTPA'
              options={[
                { key: 'LTPA', text: 'LTPA', value: 'LTPA' },
                { key: 'ProdCond', text: 'Product Condition', value: 'ProdCond' },
                { key: 'OrderAcc', text: 'Order Accuracy', value: 'OrderAcc' },
                { key: 'WaitTm', text: 'Reasonable Wait Time', value: 'WaitTm' }
              ]}
              onChange={(_, { value }) => {
                setMetric(value);
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <OnlineVoc onlineVocList={onlineVocQuery.data} level={level} metric={metric} timeframe={timeframe} />
    </Segment>
  );
};
