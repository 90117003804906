import { Header } from 'semantic-ui-react';
import { DataTable } from '../Common/DataTable';

export const PurgePackdown = ({ data, dataScores, entityField, findItem, level, timeframe }) => {
  const levelName = level === 'district' ? 'Stores' : 'Districts';

  const purgePackDown = data.reduce((acc, curr) => {
    const sideExeData = dataScores?.find(r => r[entityField] === curr[entityField]);
    if (!sideExeData) return acc;

    let item = findItem(acc, entityField, curr);

    if (item) {
      item[timeframe + '_TOTAL_TASK_COUNT'] += curr[timeframe + '_TOTAL_TASK_COUNT'];
      item[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT'] = sideExeData[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT'];
      item.RMNG = item[timeframe + '_TOTAL_TASK_COUNT'] - item[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT'];
    } else {
      acc.push({
        [entityField]: curr[entityField],
        [timeframe + '_TOTAL_TASK_COUNT']: curr[timeframe + '_TOTAL_TASK_COUNT'],
        [timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT']: sideExeData[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT'],
        RMNG: curr[timeframe + '_TOTAL_TASK_COUNT'] - sideExeData[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT']
      });
    }

    return acc;
  }, []);

  const dataObj = {
    headers: [{ name: level === 'district' ? 'Store' : 'District' }, { name: 'Comp Tasks' }, { name: 'Remaining Sent #' }],
    data: purgePackDown.map(data => [
      {
        stringValue: data[entityField],
        link: {
          to: `/osa/Purge/${level === 'district' ? 'store' : 'district'}`,
          params: level === 'district' ? { strNbr: data[entityField] } : { dstNbr: data[entityField] }
        }
      },
      {
        numValue: data[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT']
      },
      {
        numValue: data.RMNG
      }
    ]),
    sortable: true,
    sortableDefault: 0
  };

  return (
    <>
      <Header textAlign='center'>Purge Packdown by {levelName}</Header>
      <DataTable data={dataObj} />
    </>
  );
};
