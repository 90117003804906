export const formatSku = sku => { // Format SKUs, 10-digit (xxxx-xxx-xxx) versus 6-digit (xxx-xxx)
  if (sku.length > 6) return sku.substring(0, 4) + '-' + sku.substring(4, 7) + '-' + sku.substring(7)

  return sku.substring(0, 3) + '-' + sku.substring(3)
}

export const skuStringFormatter = (skuValue) => {
  let sku = '' + skuValue
  let half = sku.length / 2
  return `${sku.slice(0, half)}\u200b${sku.slice(half)}`
}

export const formatNumberWithCommas = (number, decimals = 0) => number.toLocaleString(undefined, { maximumFractionDigits: decimals })