import useQueryApi from "./useQueryApi";
import { columnName } from "../utils/constantsByLevel";

export const useFetchLevelName = ({ level, locNbr, fpToggle }) => {
  const { data = [], isLoading } = useQueryApi(`query/getLevelName/${level}`, { locNbr, fpToggle });

  return {
    currentLevelName: data.length && String(data[0][columnName[level]]).toLowerCase(),
    isLoadingName: isLoading
  }
}
