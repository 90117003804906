import React, { useState } from 'react';
import { Header, Loader, Dropdown, Grid, Modal, Segment, Button } from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon';
import { getCalloutStyle, getDollarString } from '../Common/TableCellUtils';
import { DataTable } from '../Common/DataTable';
import { UnitMenu } from '../Common/UnitMenu';
import { timeframeCode } from '../Common/timeframeCode';
import { capitalizeFirstLetter } from '../../utils/string';
import { useQueryParams } from '../../hooks/useQueryParams';
import { METRIC_CONSTANTS } from '../../utils/constantsByLevel';
import { formatSku } from '../../utils/formatter';
import useQueryApi from '../../hooks/useQueryApi';

const unitMap = { NOM: '#', PCT: '%' };
const unitMapDollar = { NOM: '$', PCT: '%' };

const QUERIES = {
  store: 'querySlsSkuByStrScls',
  district: 'querySlsSkuByDstScls',
  region: 'querySlsSkuByRgnScls',
}

export const SubclassDrilldown = withRouter(({ timeframe, settimeframe, level, fpToggle }) => {
  const [metric, setMetric] = useState('SLS');
  const [unit, setUnit] = useState('NOM');

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState([]);

  const { strNbr = 0, dstNbr = 0, rgnNbr = 0, subClassNbr = 0 } = useQueryParams();
  const locNbr = strNbr || dstNbr || rgnNbr ||  0;

  const { data, isLoading } = useQueryApi(QUERIES[level], { locNbr, subClassNbr, fpToggle });

  if (isLoading) {
    return <Loader active>Loading...</Loader>;
  }

  let deptNbr = subClassNbr.toString().substring(1, 3);

  let deptNm = data.length > 0 ? deptNbr + '-' + data[0]['DEPT_NM'] : deptNbr;

  let classNbr = subClassNbr.toString().substring(0, 7);

  let classNm = data.length > 0 ? classNbr + '-' + data[0]['CLASS_DESC'] : classNbr;

  let subClassNm = data.length > 0 ? subClassNbr + '-' + data[0]['SUB_CLASS_DESC'] : subClassNbr;

  const allSkus = data.filter(row => {
    return row && row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe];
  });

  const headerByMetric = (metric, unit) => {
    switch (metric) {
      case 'SLS':
        return [{ name: 'Sales Amt ($)' }, { name: `Sales vLY ${unitMapDollar[unit]}` }];
      case 'UNT':
        return [{ name: `Unit Sales #}` }, { name: `Unit vLY ${unitMap[unit]}` }];
      case 'TXN':
        return [{ name: 'Trans Cnt #' }, { name: `Trans vLY ${unitMap[unit]}` }];
      default:
        return [{ name: 'Avg Ticket ($)' }, { name: `Avg Ticket vLY ${unitMapDollar[unit]}` }];
    }
  };

  const propsByMetric = {
    SLS: ['TY_SLS_AMT', 'SLS_COMP_NOM', 'SLS_COMP_PCT'],
    UNT: ['TY_UNT_SLS', 'UNT_COMP_NOM', 'UNT_COMP_PCT'],
    TXN: ['TY_TXN_CNT', 'TXN_COMP_NOM', 'TXN_COMP_PCT'],
    AVG_TCKT: ['TY_AVG_TCKT', 'AVG_TCKT_COMP_NOM', 'AVG_TCKT_COMP_PCT']
  };

  const tableData = headerByMetric(metric, unit);

  const objData = {
    sortableDefault: 0,
    headers: [{ name: 'SKU ' }, { name: 'SKU Description ' }, ...tableData],
    data: allSkus.map(data => [
      {
        stringValue:
          <label style={{ cursor: 'pointer' }} onClick={() => {
            setOpen(true);
            setModalData(data);
          }}>
            {formatSku(data['SKU_NBR'])}
          </label>,
        customStyle: { color: '#EE7125', textDecoration: 'underline' },
        orderBy: data['SKU_NBR']
      },
      {
        stringValue: data['SKU_DESC']
      },
      metric === 'SLS' || metric === 'AVG_TCKT'
        ? {
          decimals: 2,
          dollarValue: metric === 'SLS' || metric === 'AVG_TCKT' ? data[propsByMetric[metric][0]] : undefined,
          customStyle: getCalloutStyle(data[propsByMetric[metric][0]])
        }
        : {
          numValue: metric === 'TXN' || metric === 'UNT' ? data[propsByMetric[metric][0]] : undefined,
          customStyle: getCalloutStyle(data[propsByMetric[metric][0]])
        },
      unit === 'NOM'
        ? {
          stringValue:
            metric === 'SLS' || metric === 'AVG_TCKT' ? getDollarString(data[propsByMetric[metric][1]], 2) : undefined,
          numValue: metric === 'TXN' || metric === 'UNT' ? data[propsByMetric[metric][1]] : undefined,
          compareStyle: true
        }
        : {
          decimals: 2,
          pctValue: data[propsByMetric[metric][2]],
          compareStyle: true
        }
    ]),
    sortable: true
  };
  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} textAlign='left'>
            <Grid.Row>
              <a
                href={`/${level}/overview?${METRIC_CONSTANTS[level]['urlParam']}=${locNbr}`}
                style={{ color: '#EE7125', textDecoration: 'underline' }}>
                Overview
              </a>
              {' / '}
              <Link
                style={{ color: '#EE7125', textDecoration: 'underline' }}
                to={`/${level}/sales/dept?${METRIC_CONSTANTS[level]['urlParam']}=${locNbr}&deptNbr=${deptNbr}`}>
                {deptNm}
              </Link>
              {' / '}
              <Link
                style={{ color: '#EE7125', textDecoration: 'underline' }}
                to={`/${level}/sales/class?${METRIC_CONSTANTS[level]['urlParam']}=${locNbr}&classNbr=${classNbr}`}>
                {classNm}
              </Link>
              {' / '}
              {subClassNm}
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>Metric:</Grid.Row>
          <Grid.Row>
            <Dropdown
              defaultValue={metric}
              selection
              scrolling
              compact
              size='small'
              options={[
                { key: 'SLS', text: 'Sales', value: 'SLS' },
                { key: 'UNT', text: 'Units', value: 'UNT' },
                { key: 'TXN', text: 'Trans', value: 'TXN' },
                { key: 'AVG_TCKT', text: 'Avg Ticket', value: 'AVG_TCKT' }
              ]}
              onChange={(_, { value }) => {
                setMetric(value);
              }}
            />
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={8}>
          <Grid.Row>Timeframe:</Grid.Row>
          <Grid.Row>
            {new Date().getDay() === 1 ? (
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} />
            ) : (
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
            )}
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <Header textAlign='left' style={{ marginTop: '2.5rem', marginBottom: '2rem' }}>
        Overview - Sales - Skus - {capitalizeFirstLetter(level)} {locNbr}, {allSkus[0]?.RGN_NM}
        <Header.Subheader>Data as of {allSkus[0]?.TRNSFM_DT}</Header.Subheader>
      </Header>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Grid.Row>Units:</Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Column>
          <Grid.Column width={8} />
        </Grid.Row>
      </Grid>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <DataTable data={objData} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {open && (
        <Modal
          closeIcon
          open={open}
          onClose={() => {
            setOpen(false);
          }}>
          <Segment color='orange'>
            <Grid>
              <Grid.Column width={16}>
                <Grid.Row>
                  <Header textAlign='center'>
                    {formatSku(modalData['SKU_NBR'])}
                  </Header>
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>
                  <Header>Class</Header>
                </Grid.Row>
                <Grid.Row>
                  <p textAlign='center'>{modalData['EXT_CLASS_NBR'] + '-' + modalData['CLASS_DESC']}</p>
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>
                  <Header>Subclass</Header>
                </Grid.Row>
                <Grid.Row>
                  <p textAlign='center'>{modalData['EXT_SUB_CLASS_NBR'] + '-' + modalData['SUB_CLASS_DESC']}</p>
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>
                  <Header>SKU Description</Header>
                </Grid.Row>
                <Grid.Row>
                  <p textAlign='center'>{modalData['SKU_DESC']}</p>
                </Grid.Row>
              </Grid.Column>
              &nbsp; &nbsp;
              <Grid.Column>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>
                  <Button
                    onClick={() => {
                      setOpen(false);
                    }}
                    style={{ color: 'white', backgroundColor: '#EE7125' }}
                    content='Close'
                  />
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
              </Grid.Column>
            </Grid>
          </Segment>
        </Modal>
      )}
    </>
  );
});
