import { createContext } from 'react';

import { useQuery } from 'react-query';
import { useQueryParams } from '../hooks/useQueryParams';

import { getData } from '../service/DataService';
import { OverviewMenu } from '../components/Overviews/Region/OverviewMenu';
import { useParams } from 'react-router-dom';

export const OverviewContext = createContext();

const QUERYS_CONSTANTS = {
  region: {
    salesSubClasses: 'querySlsSclsByRgn',
    salesClasses: 'querySlsClsByRgn',
    summary: 'querySwmRegion',
    safetyDetails: 'querySafetyIncOshaLtGlbiByRgn',
    metrictByLevel: 'querySwmDstMetricsByRgn'
  },
  division: {
    salesSubClasses: 'querySlsSclsByRgn',
    salesClasses: 'querySlsClsByRgn',
    summary: 'querySwmDivision',
    safetyDetails: 'querySafetyIncOshaLtGlbiByDiv',
    metrictByLevel: 'querySwmRgnMetricsByDiv'
  }
};

const OverviewContextProvider = ({ fpToggle, levelName, ...props }) => {
  const { rgnNbr, divNbr } = useQueryParams();
  const queryParam = rgnNbr || divNbr;
  const { level } = useParams();
  const isRegion = level === 'region';
  const isDivision = level === 'division';
  const {
    data: salesDeptData = [],
    isError: isErrorSalesDept,
    isLoading: isLoadingSalesDept
  } = useQuery([`query/deptSales/${level}`, { fpToggle, locNbr: queryParam }], getData);
  const {
    data: deptInvData = [],
    isError: isErrorDeptInv,
    isLoading: isLoadingDepInv
  } = useQuery([`query/invDept/${level}`, { fpToggle, locNbr: queryParam }], getData);
  const {
    data: slsSclsByRgnData = [],
    isError: isErrorslsSclsByRgnData,
    isLoading: isLoadingslsSclsByRgnData
  } = useQuery(['querySlsSclsByRgn', { locNbr: rgnNbr, fpToggle }], getData);
  const {
    data: slsClsByRgnData = [],
    isError: isErrorSlsClsByRgn,
    isLoading: isLoadingSlsClsByRgn
  } = useQuery(['querySlsClsByRgn', { locNbr: rgnNbr, fpToggle }], getData, { enabled: isRegion });
  const {
    data: slsClsByDivData = [],
    isError: isErrorSlsClsByDiv,
    isLoading: isLoadingSlsClsByDiv
  } = useQuery(['querySlsClsByDiv', { locNbr: divNbr, fpToggle }], getData, { enabled: isDivision });
  const {
    data: metricsByLevelData = [],
    isLoading: isLoadingMetrictsByLevel,
    isError: isErrorMetrictsByLevel
  } = useQuery([QUERYS_CONSTANTS[level]['metrictByLevel'], { locNbr: queryParam, fpToggle }], getData);
  const {
    data: swmDetailsData = [],
    isError: isErrorSwmDetails,
    isLoading: isLoadingSwmDetails
  } = useQuery([QUERYS_CONSTANTS[level]['summary'], { locNbr: queryParam, fpToggle }], getData);
  const {
    data: SafetyIncOshaLtGlbiData = [],
    isError: isErrorSafetyIncOshaLtGlbi,
    isLoading: isLoadingSafetyIncOshaLtGlbi
  } = useQuery([[QUERYS_CONSTANTS[level]['safetyDetails']], { locNbr: queryParam, fpToggle }], getData);
  const {
    data: slsSkuRgnData = [],
    isError: isErrorSlsSkuRgn,
    isLoading: isLoadingSlsSkuRgn
  } = useQuery(['querySlsSkuByRgn', { locNbr: rgnNbr, fpToggle }], getData, { enabled: isRegion });
  const {
    data: slsSkuDivData = [],
    isError: isErrorSlsSkuDiv,
    isLoading: isLoadingSlsSkuDiv
  } = useQuery(['querySlsSkuByDiv', { locNbr: divNbr, fpToggle }], getData, { enabled: isDivision });

  const dataAsOf = metricsByLevelData[0]?.TRNSFM_DT;

  const value = {
    ...props,
    deptInvData,
    isError:
      isErrorSalesDept ||
      isErrorDeptInv ||
      isErrorslsSclsByRgnData ||
      isErrorSlsClsByRgn ||
      isErrorSlsClsByDiv ||
      isErrorMetrictsByLevel ||
      isErrorSwmDetails ||
      isErrorSafetyIncOshaLtGlbi ||
      isErrorSlsSkuRgn ||
      isErrorSlsSkuDiv,
    isOverviewTabsLoading:
      isLoadingSalesDept ||
      isLoadingDepInv ||
      isLoadingslsSclsByRgnData ||
      isLoadingSlsClsByRgn ||
      isLoadingSlsClsByDiv ||
      isLoadingSwmDetails ||
      isLoadingSafetyIncOshaLtGlbi ||
      isLoadingMetrictsByLevel ||
      isLoadingSlsSkuRgn ||
      isLoadingSlsSkuDiv,
    rgnNbr,
    salesDeptData,
    slsSclsByRgnData,
    fpToggle,
    slsClsData: isRegion ? slsClsByRgnData : slsClsByDivData,
    metricsByDistrict: metricsByLevelData,
    swmDetailsData,
    SafetyIncOshaLtGlbiByRgnData: SafetyIncOshaLtGlbiData,
    level,
    divNbr,
    dataAsOf,
    levelName,
    salesSkuData: isRegion ? slsSkuRgnData : slsSkuDivData
  };

  return (
    <OverviewContext.Provider value={value}>
      <OverviewMenu />
    </OverviewContext.Provider>
  );
};

export default OverviewContextProvider;
