const COMPLETED_TASK_COUNT_FIELD = '_COMPLETED_TASK_COUNT'
const SKU_BAY_COMPLETED_TASK_COUNT_FIELD = '_STR_SKU_BAY_COMPLETED_TASK_COUNT'
const SKU_BAY_TASK_GOAL_FIELD = '_STR_SKU_BAY_TASK_GOAL'
const SKUS_PACKED_DOWN_FIELD = '_SKUS_PACKED_DOWN'
const TOTAL_TASK_COUNT_FIELD = '_TOTAL_TASK_COUNT'
const PURGE_COMPLETED_TASK_COUNT_FIELD = '_STR_PURGE_COMPLETED_TASK_COUNT'
const PURGE_TASK_GOAL_FIELD = '_STR_PURGE_TASK_GOAL'

export const getCompletedTaskCount = (data, timeframe) => parseInt(data[timeframe + COMPLETED_TASK_COUNT_FIELD] || 0)

export const getTotalTaskCount = (data, timeframe) => parseInt(data[timeframe + TOTAL_TASK_COUNT_FIELD] || 0)

export const getSkuBayCompletedTasks = (data, timeframe) => parseInt(data[timeframe + SKU_BAY_COMPLETED_TASK_COUNT_FIELD] || 0)

export const getSkuBayTaskGoal = (data, timeframe) => parseInt(data[timeframe + SKU_BAY_TASK_GOAL_FIELD] || 0)

export const getSkuPackDown = (data, timeframe) => parseInt(data[timeframe + SKUS_PACKED_DOWN_FIELD] || 0)

export const getPurgeCompletedTasks = (data, timeframe) => parseInt(data[timeframe + PURGE_COMPLETED_TASK_COUNT_FIELD] || 0)

export const getPurgeTaskGoal = (data, timeframe) => parseInt(data[timeframe + PURGE_TASK_GOAL_FIELD] || 0)

export const isTaskOriginManager = data => data.TASK_ORIGIN === 'MANAGER'

export const isTaskOriginSidekick = data => data.TASK_ORIGIN === 'SIDEKICK'

export const isTaskTypeBay = data => data.TASK_TYPE === 'BAY'

export const isTaskTypeLogged = data => data.TASK_TYPE === 'LOGGED'

export const isTaskTypeSku = data => data.TASK_TYPE === 'SKU'

export const increaseSidekickData = (data, row, timeframe) => {
  data.skuBayComp += getCompletedTaskCount(row, timeframe);
  data.skuBaySent += getTotalTaskCount(row, timeframe);

  if (isTaskTypeSku(row)) {
    data.skuComp += getCompletedTaskCount(row, timeframe);
    data.skuSent += getTotalTaskCount(row, timeframe);
    return;
  }

  if (isTaskTypeBay(row)) {
    data.bayComp += getCompletedTaskCount(row, timeframe);
    data.baySent += getTotalTaskCount(row, timeframe);
  }
};

export const increaseManagerData = (data, row, timeframe) => {
  data.mgrComp += getCompletedTaskCount(row, timeframe);
  data.mgrSent += getTotalTaskCount(row, timeframe);
}

export const initDistrictSummaryData = () => ({
  bayComp: 0,
  baySent: 0,
  completedTasks: 0,
  logSkus: 0,
  logSkusLTF: 0,
  mgrComp: 0,
  mgrSent: 0,
  skuBaySent: 0,
  skuBayComp: 0,
  skuComp: 0,
  skuSent: 0,
  taskGoal: 0,
  totalCompletedTasks: 0,
  totalTasksGoal: 0,
  ttlCompLTF: 0,
  ttlTasksLTF: 0
});
